import {Step, StepLabel, Stepper} from '@mui/material'
import {styled} from '@mui/material/styles'
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector'
import {useMemo} from 'react'

const EmployeeStepper = ({
    activeStep,
    steps,
    textColor = null,
    onTabClick = () => {},
    showCursor = null,
}) => {
    const QontoConnector = styled(StepConnector)(({theme}) => ({
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#00C247',
                borderLeftWidth: '4px',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                borderColor: '#00C247',
                borderLeftWidth: '4px',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            borderWidth: '0',
            borderColor: '#E0E0E0',
            // borderRadius: 1,
            borderLeftWidth: '4px',
            marginLeft: '7px',
            height: '50px',
            borderStyle: 'dashed',
        },
    }))

    const sx = useMemo(
        () => ({
            '.MuiSvgIcon-root': {
                borderRadius: '50%',
                // border: '1px solid grey',
                height: '40px',
                width: '40px',
                cursor: showCursor ? 'pointer' : '',
            },
            '.MuiSvgIcon-root:not(.Mui-completed)': {
                color: '#FAFAFA',
                border: '2px solid #BDBDBD',
            },

            '.MuiSvgIcon-root.Mui-active': {
                color: 'white',
                padding: '3px',
                borderRadius: '50%',
                border: '2px solid #6078EC',
                marginY: '-3px',
            },
            '.MuiStepIcon-text': {
                fill: '#BDBDBD',
                fontWeight: 600,
                fontFamily: 'Manrope',
                fontSize: '16px',
                textAlign: 'center',
            },
            '.Mui-active .MuiStepIcon-text': {
                fill: '#6078EC',
            },
            '.MuiStepConnector-root span': {
                border: '3px #E0E0E0 solid',
            },
            '.MuiStepConnector-root.Mui-active span': {
                border: '3px #00C247 solid',
            },
            '.MuiStepConnector-root.Mui-completed span': {
                border: '3px #00C247 solid',
            },
            '.MuiStepLabel-label.Mui-completed': {
                color: '#9E9E9E',
                fontWeight: '600',
            },
            '.MuiStepLabel-label.Mui-active': {
                color: '#6078EC',
                fontWeight: '600',
            },
            '.MuiStepLabel-label': {
                color: '#9E9E9E',
                fontWeight: '600',
            },
        }),
        [showCursor]
    )

    return (
        <div className=''>
            <Stepper activeStep={activeStep} orientation='vertical' connector={<QontoConnector />}>
                {steps.map((item, index) => {
                    return (
                        <Step key={item?.label} sx={sx} onClick={() => onTabClick(index)}>
                            <StepLabel
                                StepIconProps={{
                                    sx: {
                                        '&.Mui-completed': {
                                            background: 'white',
                                            color: '#00C247',
                                            border: 'none',
                                        },
                                    },
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        color: textColor,
                                        fontFamily: 'Manrope',
                                    }}
                                >
                                    {item?.label}
                                    {item?.description ? (
                                        <div
                                            style={{
                                                fontWeight: '600',
                                                fontSize: '10px',
                                                color: '#9E9E9E',
                                            }}
                                        >
                                            {item?.description}
                                        </div>
                                    ) : null}
                                </div>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </div>
    )
}

export default EmployeeStepper
