import {React, useState, useEffect, useCallback, useRef} from 'react'

import {HIRE_FIELD_KEYS} from '../EmployeePageBody'
import UploadNewDocModal from './UploadNewDocModal'
import {
    addNewSclearanceV1Service,
    deleteEmployeeDocumentByIdService,
    deleteOnBoardingDocumentService,
    getEmployeeDocumentByIdService,
    newSequiDocPostHiringDocumentListService,
    uploadOnBoardingDocumentService,
} from '../../../../../services/Services'
import CustomToast from '../../../../../customComponents/customToast/CustomToast'
import CustomLoader from '../../../../../customComponents/customLoader/CustomLoader'
import EachDocTypeRow from './EachDocTypeRow'
import {TABLE_BORDER, getErrorMessageFromResponse} from '../../../../../helpers/CommonHelpers'
import CustomNoData from '../../../../../customComponents/customNoData/CustomNoData'
import CustomButton, {BUTTON_SIZE} from '../../../../../customComponents/customButtton/CustomButton'
import {HOST_NAME} from '../../../../../constants/SERVERS'
import {INTEGRATIONS_ID} from '../../../../../constants/constants'
import {useSelector} from 'react-redux'
import {getIntegrationListSelector} from '../../../../../redux/selectors/IntegrationSelectors'

const EmployeOnboardingDocumentsStep = ({
    employeeData,
    validationMessage,
    setUploadedDocument,
    DataFields,
    globalCompanyProfile,
}) => {
    const [openModal, setOpenModal] = useState()
    const [showTable, setShowTable] = useState(true)
    const [loading, setLoading] = useState(false)
    const [selectedEmployeecData, setSelectedEmployeecData] = useState(null)
    const [employeeDocData, setEmployeeDocData] = useState(null)
    const [sclearanceData, setSclrearanceData] = useState(null)
    const [isMailSent, setMailSent] = useState(false)
    const integrationList = useSelector(getIntegrationListSelector)
    const checkSclearnceActive = integrationList.find(
        (val) => val?.id == INTEGRATIONS_ID.sClearance && val?.status == 1
    )?.status
    useEffect(() => {
        if (employeeData?.id) getDocument()
    }, [])

    const getDocument = () => {
        setLoading(true)
        // getEmployeeDocumentByIdService(employeeData?.id)
        newSequiDocPostHiringDocumentListService(employeeData?.id)
            .then((res) => {
                setEmployeeDocData(res?.data)
                setUploadedDocument(res?.data)
                setSclrearanceData(res?.sclrearance_data)
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false)
            })
    }

    const CloseModal = () => {
        setOpenModal(false)
    }
    const onSendEmail = () => {
        const data = {
            email: sclearanceData?.email || employeeData?.email,
            first_name: sclearanceData?.first_name || employeeData?.first_name,
            last_name: sclearanceData?.last_name || employeeData?.last_name,
            description: 'Test',
            frontend_url: HOST_NAME,
            user_type_id: employeeData?.id,
            user_type: 'Onboarding',
            position_id: employeeData?.position_id,
            office_id: employeeData?.office_id,
        }
        addNewSclearanceV1Service(data)
            .then((res) => {
                setMailSent(true)
                CustomToast.success('Mail successfully sent.')
            })
            .catch((err) => CustomToast.error(getErrorMessageFromResponse(err)))
    }
    return (
        <div className='w-100 mx-auto' style={{fontSize: '14px', fontFamily: 'Manrope'}}>
            <div className='text-cmGrey500 text-center mb-10 '>
                Welcome to <span className='text-cmGrey800'> {globalCompanyProfile?.name}</span>,
                Please Complete The Following Fields. Please ensure the following information is
                correct before proceeding to the next section.
            </div>
            <div
                className='bg-cmwhite shadow-sm mb-10 w-sm-75 w-100 mx-auto py-5 '
                style={{borderRadius: '10px'}}
            >
                {showTable && (
                    <div style={{position: 'relative'}}>
                        <CustomLoader visible={loading} full />
                        <div
                            className='text-center text-cmGrey600'
                            style={{
                                fontSize: '14px',
                                fontWeight: '500',
                                fontFamily: 'Manrope',
                            }}
                        >
                            {/* Upload Mandatory Documents */}
                        </div>
                        <div className='table-responsive'>
                            <table className='table' style={{tableLayout: 'fixed', width: '100%'}}>
                                <thead className={TABLE_BORDER}>
                                    <tr
                                        className='text-cmGrey900 bg-cmGrey300'
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: '600',
                                            fontFamily: 'Manrope',
                                        }}
                                    >
                                        <th className=' p-5' style={{width: '175px'}}>
                                            Type
                                        </th>
                                        <th className=' p-5' style={{width: '150px'}}>
                                            Date Uploaded
                                        </th>
                                        <th className=' p-5 ' style={{width: '300px'}}>
                                            Attachments
                                        </th>
                                        <th className=' p-5' style={{width: '120px'}}></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {employeeDocData?.length > 0 || sclearanceData ? (
                                        <>
                                            {employeeDocData?.length > 0
                                                ? employeeDocData?.map(
                                                      (item, index) =>
                                                          !item?.is_deleted && (
                                                              <EachDocTypeRow
                                                                  key={index}
                                                                  employeeData={employeeData}
                                                                  item={item}
                                                                  userId={employeeData?.id}
                                                                  getDocument={getDocument}
                                                              />
                                                          )
                                                  )
                                                : null}
                                            {checkSclearnceActive && sclearanceData ? (
                                                <tr
                                                    key={'Sclearance'}
                                                    style={{
                                                        fontSize: '14px',
                                                        fontFamily: 'Manrope',
                                                        fontWeight: '600',
                                                    }}
                                                    className={`text-cmGrey700 stripRow ${TABLE_BORDER}`}
                                                >
                                                    <td
                                                        width={'175px'}
                                                        className={`p-5`}
                                                        style={{fontWeight: '700'}}
                                                        colSpan={3}
                                                    >
                                                        S-Clearance
                                                    </td>
                                                    <td className='p-5 d-flex  gap-4 flex-center mx-auto'>
                                                        <CustomButton
                                                            buttonLabel='Send Mail'
                                                            onClick={onSendEmail}
                                                            buttonSize={BUTTON_SIZE.small}
                                                            disabled={
                                                                sclearanceData?.id != 0 ||
                                                                isMailSent
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={4}>
                                                <div className='text-center'>
                                                    <CustomNoData
                                                        label={'No documents need to be upload'}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            <UploadNewDocModal
                show={openModal}
                handleClose={CloseModal}
                userData={employeeData}
                getDocument={getDocument}
                editData={selectedEmployeecData}
            />
        </div>
    )
}

export default EmployeOnboardingDocumentsStep
