import {useEffect, useRef, useState} from 'react'
import ReactDOM from 'react-dom'
import CustomLoader from '../../../customComponents/customLoader/CustomLoader'

const EvereeEmbededEmployeeOnboardingIframe = ({
    src = 'https://app.everee.com/embedded?token=66e4368e-28fa-4e95-b8be-e964bd2dbddf',
    onStatusChange = () => {},
}) => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const channel = new MessageChannel()
        const iframe = document.getElementById('ifrm')

        const handleLoad = (event) => {
            console.log('event')
            event.target.contentWindow.postMessage('', '*', [channel.port2])
        }

        const handleMessage = (event) => {
            console.log('event')
            console.log(event)
            onStatusChange(event?.data?.eventType)
            setLoading(false)
            // check event.data.handlerName and event.data.eventType,
            // and handle messages sent from the Everee embedded UX
        }

        iframe.addEventListener('load', handleLoad)
        channel.port1.onmessage = handleMessage

        return () => {
            iframe.removeEventListener('load', handleLoad)
            channel.port1.onmessage = null
        }
    }, [])

    return (
        <div style={{height: '100%'}}>
            <CustomLoader visible={loading} />
            <iframe
                title='Everee'
                id='ifrm'
                style={{height: '100%', width: '100%'}}
                src={src}
            ></iframe>
        </div>
    )
}
export default EvereeEmbededEmployeeOnboardingIframe
