import {
    AMAZON_S3_CONFIG,
    DEFAULT_CURRENCY,
    IMAGE_URL,
    LEAD_DAYS_FOR_FINALIZE_PAYROLL,
    MAIN_POSITTIONS_ID,
    MIN_SCHEDULE_HOUR,
    TEMPLATE_DYNAMIC_FIELD_FIND_REGEX,
    getValidDate,
} from '../constants/constants'
import ubid from 'ubid'
import images from '../assets/images'
import _ from 'lodash'
import moment from 'moment'
import {store} from '../redux/store'
import {getPositionsSelector} from '../redux/selectors/SettingsSelectors'
import {callThirdPartyApiService} from '../services/Services'
import axios from 'axios'
import {saveAs} from 'file-saver'
import {customMoment} from '../customComponents/customMoment/customMoment'
import CustomToast from '../customComponents/customToast/CustomToast'
import * as XLSX from 'xlsx'

export const getSortedObjectByKey = (unorderedObject) => {
    return Object.keys(unorderedObject)
        .sort()
        .reduce((obj, key) => {
            obj[key] = unorderedObject[key]
            return obj
        }, {})
}

export const getMillSecondsFromMinutes = (minutes = 0) => {
    return minutes * 60 * 1000
}

export const jsonToFormData = (jsonObj) =>
    Object.entries(jsonObj)?.reduce(
        (current, item) => (current.append(...item), current),
        new FormData()
    )

export const sendDataToReducer = (dispatch, type = null, payload = null) => {
    if (type) dispatch(type(payload))
}
export const IMAGE_TYPE = {
    noImage: 'noImage',
    userAvatar: 'userAvatar',
    companyLogo: 'companyLogo',
}
export const getServerImage = (image_path = null, type = IMAGE_TYPE.userAvatar) => {
    // return image_path ? `${IMAGE_URL}/${image_path}` : getDefaultImage(type)
    return image_path ? image_path : getDefaultImage(type)
}

export const getValidDomain = (domain) => {
    return /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(domain)
}

export const getDefaultImage = (type) => {
    switch (type) {
        case IMAGE_TYPE.userAvatar:
            return images.defaultUserImage
        case IMAGE_TYPE.companyLogo:
            return images.defaultCompanyImage
        case IMAGE_TYPE.noImage:
            return images.sequifiLogo
        default:
            return null
    }
}

export const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0
    return value?.toString()?.split('.')[1]?.length || 0
}

export const formattedNumberFields = (
    amountOrPercentage = 0,
    type = DEFAULT_CURRENCY,
    bindWithSpanTag = true,
    convertToNegetive = false
) => {
    let amount = Number(amountOrPercentage)
    if (convertToNegetive) amount = amount * -1
    // const totalDecimal = countDecimals(amount)
    const formattedAmount = amount
        // ?.toFixed(totalDecimal > 2 ? totalDecimal : 2)
        ?.toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (type === '$') {
        const finalAmount =
            Number(amount) < 0 ? `(${formattedAmount.replace('-', '')})` : formattedAmount
        return bindWithSpanTag ? (
            <span className={Number(amount) < 0 ? 'text-cmError text-nowrap' : 'text-nowrap'}>
                {`${type} ${finalAmount}`}
            </span>
        ) : (
            `${type} ${finalAmount}`
        )
    } else {
        return `${formattedAmount} ${type}`
    }
}
export const percentageLimitCheck = (max, value) => {
    if (Number(value) <= Number(max)) return true
    else return false
}

export const formattedNumberFieldsWithoutDecimal = (
    amountOrPercentage = 0,
    type = '',
    needOnlyValue = false
) => {
    let amount = Number(amountOrPercentage)
    const formattedAmount = amount
        ?.toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    if (type === '$') {
        const finalAmount =
            Number(amount) < 0 ? `(${formattedAmount.replace('-', '')})` : formattedAmount
        return `${type} ${finalAmount}`
    } else {
        return `${formattedAmount} ${type}`
    }
}

export const formattedPhoneNumber = (phoneNumber = null) => {
    if (!phoneNumber) return null
    let phone_number = phoneNumber
        ?.toString()
        ?.replace('+1', '')
        ?.replace('(', '')
        ?.replace(')', '')
        ?.replace('+', '')
        .replace('-', '')
    if (phone_number?.startsWith('001')) phone_number = phone_number?.substring(3)
    return phone_number?.toString()?.length > 0
        ? `(${phone_number?.toString().slice(0, 3)}) ${phone_number
              ?.toString()
              ?.slice(3, 6)}-${phone_number?.toString().slice(6)}`
        : null
}

//convert number to xxxxx1212 type format
export const convertToMaskedNumber = (num, minLength = 0) => {
    const strNum = num?.toString()
    // const maskedNum =
    //     num?.length >= minLength
    //         ? 'x'?.repeat(strNum?.length - 4) + strNum?.substring(strNum?.length - 4)
    //         : num
    // return maskedNum
    return strNum
}

export const getSecureValue = (inputNumber = null, lastCharactersLength = 4) => {
    const numberString = inputNumber?.toString()

    const maskedString =
        numberString && numberString?.length >= lastCharactersLength
            ? 'x'.repeat(numberString?.length - lastCharactersLength) +
              numberString?.slice(-lastCharactersLength)
            : null

    return maskedString ?? '-'
}

export const getBooleanValue = (customValue) => {
    const value = Number(customValue)
    if (value == true || value == 1 || value == '1' || value) return 1
    else if (value == false || value == 0 || value == '0' || !value || value == undefined) return 0
    else return null
}

export const isEmptyObjectValue = (object) => _.values(object).every(_.isEmpty)
export const isEmptyValue = (value) => _.isEmpty(value?.toString())

export const escapeRegExp = (str) => {
    if (!_.isString(str)) return ''
    return str.replace(/[-[\]\\/{}()*+?.^$|]/g, '\\$&')
}

export const getGlobalSearchData = (data = [], field = [], searchString) => {
    const searchData = []
    const searchStr = escapeRegExp(searchString).replace(/ /g, '')
    if (searchStr !== '') {
        data?.map((item) => {
            let isSearch = false
            field.map((key) => {
                if (
                    !isSearch &&
                    item?.[key]
                        ?.toLowerCase()
                        ?.toString()
                        ?.replace(/ /g, '')
                        ?.match(searchStr?.toLowerCase()?.toString())
                ) {
                    isSearch = true
                }
                return true
            })
            if (isSearch) searchData.push(item)
            return true
        })
    }
    return searchData
}

export const getMobileNumberWithoutMask = (phoneNumber) => {
    if (!phoneNumber) return null
    let phone_number = phoneNumber?.toString()?.replace('+1', '')
    if (phone_number?.startsWith('001')) phone_number = phone_number?.substring(3)
    return phone_number ? phone_number.toString()?.replace(/[- _)(]/g, '') : null
}

export const getDataWithoutMask = (data) => {
    return data ? data.toString()?.replace(/[- _)(]/g, '') : null
}

export const getErrorMessageFromResponse = (error) => {
    const field = error?.data?.error ? Object.keys(error?.data?.error)?.[0] ?? '' : null
    return (
        error?.data?.error?.[field]?.[0] ??
        error?.data?.message ??
        error?.data?.Message ??
        error?.data?.msg ??
        error?.error ??
        ''
    )
}

export const isInputValueExist = (value) => {
    return ['null', 'undefined', '', null, undefined].includes(value?.toString()?.trim()) ? 0 : 1
}
export const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()

    // Prevent the page/container scrolling
    e.stopPropagation()
}

export const getRepRedlineFromSale = (
    positionType,
    selectedState,
    sale_state_redline = 0,
    rep,
    approved_date = null
) => {
    const officeRedline = getLocationRedlineHelper(rep?.office, approved_date)?.current
    let redlines = {
        redline: null,
        redline_type: null,
        type:
            selectedState?.general_code == rep?.office?.general_code ||
            rep?.redline_amount_type == 'Fixed'
                ? 'Fixed'
                : 'Shifted',
    }

    let originalRedlineData = []
    if (rep?.self_gen_accounts && positionType != rep?.position_id) {
        if (rep?.self_gen_redline && rep?.self_gen_redline_type) {
            originalRedlineData.push({
                redline: rep?.self_gen_redline,
                start_date: rep?.self_gen_redline_type,
                redline_type: rep?.self_gen_redline_type,
            })
        }
        if (rep?.self_gen_additional_redline?.length > 0) {
            rep?.self_gen_additional_redline?.map((item) => {
                if (item?.redline && item?.start_date) originalRedlineData.push(item)
            })
        }

        const dates = originalRedlineData
            ?.filter((item) => item?.redline)
            ?.map((item) => ({
                ...item,
                start_date: moment(
                    new Date(moment(item?.start_date).format('YYYY-MM-DD 00:00:00'))
                ),
            }))

        const sortedRedlinesDates = dates
            ?.sort((a, b) => a?.start_date?.diff(b?.start_date))
            ?.reverse()

        const existRedline = sortedRedlinesDates?.find(
            (item) =>
                new Date(moment(approved_date).format('YYYY-MM-DD 00:00:00')) >= item?.start_date
        )

        if (existRedline) {
            redlines.redline = existRedline?.redline
            redlines.redline_type = existRedline?.redline_type
        }

        if (
            redlines?.type != 'Fixed' &&
            isInputValueExist(sale_state_redline) &&
            isInputValueExist(redlines.redline) &&
            isInputValueExist(officeRedline?.redline_standard)
        ) {
            redlines.redline = (
                Number(sale_state_redline) +
                (Number(redlines.redline) - Number(officeRedline?.redline_standard))
            )?.toFixed(2)
        }
    } else {
        if (rep?.redline && rep?.redline_effective_date) {
            originalRedlineData.push({
                redline: rep?.redline,
                start_date: rep?.redline_effective_date,
                redline_type: rep?.redline_type,
            })
        }
        if (rep?.additional_redline?.length > 0) {
            rep?.additional_redline?.map((item) => {
                if (item?.redline && item?.start_date) originalRedlineData.push(item)
            })
        }

        const dates =
            originalRedlineData?.length > 0
                ? originalRedlineData?.map((item) => ({
                      ...item,
                      start_date: moment(
                          new Date(moment(item?.start_date).format('YYYY-MM-DD 00:00:00'))
                      ),
                  }))
                : []

        const sortedRedlinesDates =
            dates?.length > 0
                ? dates?.sort((a, b) => a?.start_date?.diff(b?.start_date))?.reverse()
                : []

        const existRedline =
            sortedRedlinesDates?.length > 0
                ? sortedRedlinesDates?.find(
                      (item) =>
                          new Date(moment(approved_date).format('YYYY-MM-DD 00:00:00')) >=
                          item?.start_date
                  )
                : null

        if (existRedline) {
            redlines.redline = existRedline?.redline
            redlines.redline_type = existRedline?.redline_type
        }

        if (
            redlines?.type != 'Fixed' &&
            isInputValueExist(sale_state_redline) &&
            isInputValueExist(redlines?.redline) &&
            isInputValueExist(officeRedline?.redline_standard)
        ) {
            redlines.redline = (
                Number(sale_state_redline) +
                (Number(redlines.redline) - Number(officeRedline?.redline_standard))
            )?.toFixed(2)
        }
    }

    return redlines
}

export const convertHTMLToPlainText = (html) => {
    // Create a new div element
    var tempDivElement = document.createElement('div')
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html
    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || ''
}

export const getDeadlinetoFinalizePayrollDate = (date) => {
    let finalDate = null
    const day = moment(date, 'MM/DD/YYYY').day()
    let final_lead_day = LEAD_DAYS_FOR_FINALIZE_PAYROLL
    if ([1, 2].includes(day)) final_lead_day = final_lead_day + 2
    finalDate = moment(date).subtract(final_lead_day, 'days')
    return getValidDate(finalDate, 'YYYY-MM-DD')
}
export const checkAndGetInputValueFromUnitType = (unitType, value) => {
    return unitType == 'percent' && Number(value) > 100 ? '' : value
}
export const getDeadlinetoFinalizePayroll = (date, payPeriod = null) => {
    let startDate = null,
        endDate = null,
        dateIsInPayPeriod = false

    if (date) {
        const finalDate = date ? moment(getDeadlinetoFinalizePayrollDate(date)) : null
        if (payPeriod) {
            const splitDates = payPeriod?.split(' - ')
            if (splitDates?.[0]) startDate = moment(splitDates?.[0], 'MM/DD/YYYY')
            if (splitDates?.[1]) endDate = moment(splitDates?.[1], 'MM/DD/YYYY')
            if (finalDate >= startDate && finalDate <= endDate) dateIsInPayPeriod = true
        }
        return (
            <span>
                {finalDate ? getValidDate(finalDate?.format('YYYY/MM/DD')) : '-'}
                {dateIsInPayPeriod ? (
                    <span className='text-danger ms-5'>
                        <i className='bi bi-exclamation-circle text-danger' /> Overlapping pay
                        period
                    </span>
                ) : null}
            </span>
        )
    } else return null
}

export const getUserPositionMetaData = (userData) => {
    /**
     * userData should be have this parameter
     * - position_id
     * - sub_position_id
     * - is_super_admin
     * - is_manager
     */
    const position_id = userData?.position_id
    const sub_position_id = userData?.sub_position_id
    const state = store.getState()
    const allPosition = getPositionsSelector(state)
    const isUserSuperAdmin = getBooleanValue(userData?.is_super_admin)
    const isUserManager =
        getBooleanValue(userData?.is_manager) == 1 && getBooleanValue(userData?.is_super_admin) == 0
    const isUserCloser =
        !isUserManager &&
        !isUserSuperAdmin &&
        [MAIN_POSITTIONS_ID.closer].includes(position_id?.toString())
    const isUserSetter =
        !isUserManager &&
        !isUserSuperAdmin &&
        [MAIN_POSITTIONS_ID.setter].includes(position_id?.toString())

    const subPositionName = allPosition.find((item) => item?.id == sub_position_id)?.position_name

    if (isUserSetter) {
        return {
            position_name: 'Setter',
            position_color: 'cminfo',
            subposition_name: subPositionName,
        }
    } else if (isUserCloser) {
        return {
            position_name: subPositionName,
            position_color: 'cmPurple',
        }
    } else if (isUserManager) {
        return {
            position_name: subPositionName,
            position_color: 'cmOrange',
        }
    } else if (isUserSuperAdmin) {
        return {
            position_name: 'Super Admin',
            position_color: 'cmgreen',
        }
    } else {
        return {
            position_name: subPositionName,
            position_color: 'cmGrey400',
        }
    }
}

export const getEmployeeRedlineHelper = (
    employeeData = null,
    trigger_date = null,
    self_gen = false
) => {
    const triggerDate = trigger_date
        ? new Date(moment(trigger_date).format('YYYY-MM-DD 00:00:00'))
        : new Date(moment().format('YYYY-MM-DD 00:00:00'))
    const redline_data = self_gen ? employeeData?.self_gen_redline_data : employeeData?.redline_data
    let currentRedline = {
        redline_amount: self_gen ? employeeData?.self_gen_redline : employeeData?.redline_amount,
        redline_type: self_gen ? employeeData?.self_gen_redline_type : employeeData?.redline_type,
        start_date: null,
    }

    const pastRedlineData = redline_data?.filter(
        (item) =>
            new Date(moment(item?.start_date, 'YYYYY-MM-DD').format('YYYY-MM-DD 00:00:00')) <
            triggerDate
    )

    const upcomingRedlineData = redline_data?.filter(
        (item) =>
            new Date(moment(item?.start_date, 'YYYYY-MM-DD').format('YYYY-MM-DD 00:00:00')) >
            triggerDate
    )

    const dates = redline_data?.map((item) => ({
        ...item,
        start_date: moment(new Date(moment(item?.start_date).format('YYYY-MM-DD 00:00:00'))),
    }))
    const sortedRedlinesDates = dates?.sort((a, b) => a?.start_date?.diff(b?.start_date))?.reverse()
    const existRedline = sortedRedlinesDates?.find((item) => triggerDate >= item?.start_date)

    if (existRedline) {
        currentRedline.redline_amount = existRedline?.redline
        currentRedline.redline_type = existRedline?.redline_type
        currentRedline.start_date = existRedline?.start_date
    }

    return {
        current: currentRedline,
        past: pastRedlineData,
        upcoming: upcomingRedlineData,
    }
}

export const getLocationRedlineHelper = (locationData = null, trigger_date = null) => {
    const triggerDate = trigger_date
        ? moment(moment(trigger_date).format('YYYY-MM-DD 00:00:00')).toDate()
        : moment(moment().format('YYYY-MM-DD 00:00:00')).toDate()

    let pastRedlineData = [],
        upcomingRedlineData = []
    let currentRedline = {
        redline_min: locationData?.redline_min,
        redline_standard: locationData?.redline_standard,
        redline_max: locationData?.redline_max,
        effective_date: locationData?.effective_date,
    }

    if (triggerDate && isInputValueExist(locationData?.redline_standard)) {
        let redline_data = locationData?.redline_data ? [...locationData?.redline_data] : []
        redline_data.push({
            redline_min: locationData?.redline_min,
            redline_standard: locationData?.redline_standard,
            redline_max: locationData?.redline_max,
            effective_date: locationData?.effective_date,
        })

        pastRedlineData = redline_data?.filter(
            (item) =>
                moment(
                    moment(item?.effective_date, 'YYYYY-MM-DD').format('YYYY-MM-DD 00:00:00')
                ).toDate() < triggerDate
        )
        upcomingRedlineData = redline_data?.filter(
            (item) =>
                moment(
                    moment(item?.effective_date, 'YYYYY-MM-DD').format('YYYY-MM-DD 00:00:00')
                ).toDate() > triggerDate
        )

        const dates = redline_data?.map((item) => ({
            ...item,
            effective_date: moment(
                moment(moment(item?.effective_date).format('YYYY-MM-DD 00:00:00')).toDate()
            ),
        }))
        const sortedRedlinesDates = dates
            ?.sort((a, b) => a?.effective_date?.diff(b?.effective_date))
            ?.reverse()

        const existRedline = sortedRedlinesDates?.find(
            (item) => triggerDate >= item?.effective_date
        )

        if (existRedline) {
            currentRedline = {
                ...existRedline,
                effective_date: getValidDate(existRedline?.effective_date, 'YYYY/MM/DD', true),
            }
        }
    }

    return {
        current: currentRedline,
        past: pastRedlineData,
        upcoming: upcomingRedlineData,
    }
}
export const bindHtmlWithCss = (html = '', css = '') => {
    return `<head><style>${css}</style>${html}`
}
export const isObjectHasEmptyValue = (data) => {
    return Object.keys(data).every((key) => [undefined, null, ''].includes(data[key]))
}

export const downloadAnyFileHelper = (data, fileName) => {
    const url = data?.url ?? window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
    link.remove()
}
export const downloadXlSXFileHelper = (
    url,
    fileName,
    showToast = true,
    isUpdatedFileName = false
) => {
    axios
        .get(url, {responseType: 'arraybuffer'})
        .then((response) => {
            const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const name = isUpdatedFileName
                ? fileName
                : `${fileName} - ${getValidDate(customMoment.todayDate(), 'DD MMM YY hh:mm')}.xlsx`
            saveAs(blob, name)
            if (showToast) CustomToast.success('File Downloaded Successfully')
        })
        .catch((error) => {
            CustomToast.error('Failed to download')
        })
}

export const getTextForSecurity = (data = null, lastDigit = 4) => {
    let lastStartIndex = data ? data?.toString()?.replace(/-/gim)?.length - lastDigit : null
    let value = data
        ? data
              ?.toString()
              ?.replace(/-/gim)
              ?.slice(lastStartIndex, lastStartIndex + lastDigit)
        : ''
    return value
}

//Function to show filter Counts
export const displayfilterCounts = (filterData) => {
    const count = filterData ? Object.values(filterData)?.filter((value) => value)?.length : 0
    return count
}

// table vertical line style Bootstrap
export const TABLE_BORDER = 'border-bottom border-sqGray200'

export const detectCustomFields = (templateData, DYNAMIC_FIELD) => {
    let fieldToCheck = [
        'Page_Break',
        'Compensation_Plan',
        's:employee',
        'Annexure',
        's:manager',
        'text_entry',
    ]
    let otherUniqueFields = []
    const regexFoundData = templateData?.match(TEMPLATE_DYNAMIC_FIELD_FIND_REGEX)
    Object.keys(DYNAMIC_FIELD)?.map((item) => {
        fieldToCheck.push(DYNAMIC_FIELD[item]?.key)
    })
    regexFoundData
        ?.filter((item, index, self) => self?.indexOf(item) == index)
        ?.filter((item) => {
            let exist = fieldToCheck.includes(
                item?.toString()?.replace(/\[/gim, '')?.replace(/\]/gim, '')?.replace(/\s/g, '')
            )

            if (!exist) {
                otherUniqueFields.push(item)
            }
        })
    return otherUniqueFields
}

export const getFinalTemplateContent = (finalString = '') => {
    const replaceStringAtIndex = (str, index, replacement) => {
        return str.substring(0, index) + replacement + str.substring(index)
    }
    const regex = /\[([^[\]]*)\](?! )/gm

    let match = [...finalString?.matchAll(regex)]
    match?.map((matchItem, matchIndex) => {
        let stringLength = matchItem?.[0]?.length
        let addSpaceIndex = matchItem?.index + stringLength + matchIndex
        finalString = replaceStringAtIndex(finalString, addSpaceIndex, ' ')
    })
    return finalString
}

export const isImageUrl = (url) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'svg']
    const fileExtension = url?.split('.').pop().toLowerCase()
    return imageExtensions.includes(fileExtension)
}

export const urlToBase64 = async (url, callback) => {
    const response = await callThirdPartyApiService({url, responseType: 'blob'})
    // const blob = response.blob()
    return await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(response)
    })
}

export const isValidURL = (str) => {
    var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ) // fragment locator
    return !!pattern.test(str)
}

export const getBindedUserData = (data) => {
    if (Array.isArray(data)) {
        return data?.map((item) => ({
            ...item,
            name: `${item?.first_name} ${item?.last_name}`,
        }))
    } else {
        return {
            ...data,
            name: `${data?.first_name} ${data?.last_name}`,
        }
    }
}

export const fileNameWithoutS3Url = (link = '', isPrivate = false) => {
    return link?.replace(
        isPrivate ? AMAZON_S3_CONFIG.s3_private_bucket_url : AMAZON_S3_CONFIG.s3_bucket_url,
        ''
    )
}

export const removeZeroWidthCharacters = (str = '') => {
    return str.replace(/[\u200B-\u200D\uFEFF]/g, '')
}

export const sortCoordinates = (items) => {
    return items.sort(function (a, b) {
        //sort by x, secondary by y
        return a.x == b.x ? a.y - b.y : a.x - b.x
    })
}

export const manipulatedDealerFeeValue = (value) => {
    if (isInputValueExist(value)) {
        if (Number(value) < 1) return Number(value) * 100
        else return Number(value)
    } else {
        return null
    }
}
export const replaceRange = (s, start, end, substitute) => {
    return s.substring(0, start) + substitute + s.substring(end)
}
export const calculatePercentage = (value = 0, totalValue = 0) => {
    return Math.ceil((value * 100) / totalValue || 0) ?? 0
}

export const getDurationBetweenTime = (timeFrom = null, timeTo = null, subtractMinutes = 0) => {
    const validTimeFrom = moment(timeFrom).isValid()
    const validTimeTo = moment(timeTo).isValid()
    if (validTimeFrom && validTimeTo) {
        let timeA = moment(timeFrom, 'ddd MMM DD YYYY HH:mm:ss ZZ')
        let timeB = moment(timeTo, 'ddd MMM DD YYYY HH:mm:ss ZZ')
        let duration = moment.duration(timeB?.diff(timeA))
        duration.subtract(subtractMinutes, 'minutes')
        let hoursDifference = duration?.hours()
        let minutesDifference = duration?.minutes()

        // if (subtractMinutes) minutesDifference -= Number(subtractMinutes)
        // if (minutesDifference < 0) {
        //     let totalMinutesDifference = hoursDifference * 60 + minutesDifference
        //     hoursDifference = Math.floor(totalMinutesDifference / 60)
        //     minutesDifference = totalMinutesDifference % 60
        // }

        let formattedDifference = null
        if (hoursDifference >= 0 && minutesDifference >= 0) {
            formattedDifference = `${hoursDifference
                ?.toString()
                ?.padStart(2, '0')}:${minutesDifference?.toString()?.padStart(2, '0')}`
        }
        return formattedDifference
    }
    return null
}

export const calculateExtraTime = (totalTime, minHour = MIN_SCHEDULE_HOUR) => {
    if (!totalTime) return null
    let [formattedHours, formattedMinutes] = totalTime?.split(':')?.map(Number)
    let isGreaterThanDefaultHours =
        formattedHours > minHour || (formattedHours === minHour && formattedMinutes > 0)

    let excessHours = 0
    let excessMinutes = 0
    if (isGreaterThanDefaultHours) {
        let totalMinutes = formattedHours * 60 + formattedMinutes
        let excessTotalMinutes = totalMinutes - minHour * 60
        excessHours = Math.floor(excessTotalMinutes / 60)
        excessMinutes = excessTotalMinutes % 60
    }
    let formattedExtraTime = `${excessHours.toString().padStart(2, '0')}:${excessMinutes
        ?.toString()
        ?.padStart(2, '0')}`

    return {isGreaterThanDefaultHours, formattedExtraTime}
}
export const datefilter = () => {
    let years = []
    let currentYear = new Date()
    currentYear = currentYear.getFullYear()
    for (let i = 2020; i <= currentYear; i++) {
        years.push({name: i.toString(), value: i.toString()})
    }
    years.push({name: 'Last 12 Months', value: 'last_12_months'})
    return years
}

export const getDatesBetween = (start, end) => {
    if (!getValidDate(start) && !getValidDate(end)) return []
    return Array.from({length: moment(end)?.diff(moment(start), 'days') + 1}, (_, i) =>
        moment(start)?.add(i, 'days')?.format('YYYY-MM-DD')
    )
}
// truncated html will addes elipssis to the end of html after certain charcters, which we will pass into the function as maxLength - 'AMAN SONI'
export const TruncatedHtml = ({content, maxLength, className, style}) => {
    if (content.length <= maxLength) {
        return (
            <div style={style} className={className} dangerouslySetInnerHTML={{__html: content}} />
        )
    }
    const truncatedContent = content.substring(0, maxLength) + '...'
    return (
        <div
            style={style}
            className={className}
            dangerouslySetInnerHTML={{__html: truncatedContent}}
        />
    )
}

export const jsonToExcelBlob = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
    const blob = new Blob([excelBuffer], {
        // type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        type: 'application/ms-excel',
    })

    saveAs(blob, 'data.xlsx')
    return blob
}

export const jsonToExcelFile = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'})
    const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        // type: 'application/ms-excel',
    })
    var file = new File([blob], 'import_export.xlsx')

    // To Save uploaded file
    // saveAs(blob, 'data.xlsx')

    return file
}

export const excelToJson = (file, fields) =>
    new Promise((resolve) => {
        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                const data = e.target.result
                const workbook = XLSX.read(data, {type: 'array', cellDates: true})
                const sheetName = workbook.SheetNames[0]
                const worksheet = workbook.Sheets[sheetName]
                let json = XLSX.utils.sheet_to_json(worksheet)
                let updatedJson = trimAndReplaceObjectKeys(json, fields)
                resolve(updatedJson)
            }
            reader.readAsArrayBuffer(file)
        } else {
            resolve(null)
        }
    })

export const getExcelHeaders = (file) =>
    new Promise((resolve) => {
        if (file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                const data = e.target.result
                const workbook = XLSX.read(data, {type: 'array'})
                const sheetName = workbook.SheetNames[0]
                const worksheet = workbook.Sheets[sheetName]
                let json = XLSX.utils.sheet_to_json(worksheet, {header: 1})
                const header = json.shift()
                resolve(header)
            }
            reader.readAsArrayBuffer(file)
        } else {
            resolve(null)
        }
    })

export const trimAndReplaceObjectKeys = (arr, wholeFields) => {
    let fields = _.cloneDeep(wholeFields)
    const allExcelFields = new Set()
    let finalData = arr.map((obj) => {
        let newObj = {}
        let data = Object.keys(obj).reduce((acc, key) => {
            const trimmedKey = key.trim()
            allExcelFields.add(trimmedKey)
            fields?.length > 0 &&
                fields?.forEach((item) => {
                    if (item?.excel_field == trimmedKey) acc[item?.name] = obj[key] ?? ''
                })
            return acc
        }, {})

        fields?.length > 0 &&
            fields
                ?.sort((a, b) => a?.sequence - b?.sequence)
                ?.forEach((item) => (newObj[item?.name] = data[item?.name] ?? ''))
        return newObj
    })

    return {
        data: finalData,
        allExcelFields: Array.from(allExcelFields),
    }
}

export const getUniqueBrowserId = () =>
    new Promise((resolve, reject) => {
        ubid.get(function (error, signatureData) {
            if (error) {
                reject(error)
            }
            resolve(signatureData?.browser?.signature)
        })
    })
export const lazyRetry = function (componentImport, name) {
    return new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        )
        // try to import the component
        componentImport()
            .then((component) => {
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false') // success so reset the refresh
                resolve(component)
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true') // we are now going to refresh
                    return window.location.reload() // refresh the page
                }
                reject(error) // Default error behaviour as already tried refresh
            })
    })
}
