import _ from 'lodash'
import {CommonLabel} from '../customInput/CustomInput'
import {MultiSelect} from 'primereact/multiselect'
import CustomLoader from '../../customLoader/CustomLoader'
import './AutocompleteDropdownStyles.css'
import {KTSVG} from '../../../_metronic/helpers'
import {fontsFamily} from '../../../assets/fonts/fonts'

const CustomAutoCompleteDropdown = ({
    onChange = null,
    value = null,
    filteredValue = null,
    searchValue = null,
    label = null,
    textColorClassName = 'text-sqGray900',
    options = [],
    selectedOptions = [],
    displayKey = 'name',
    placeholder = 'Select',
    className,
    required = false,
    valueKey = 'name',
    showSelectAll = true,
    placeholderIcon = 'bi bi-plus-square',
    errorMessage = '',
    name = null,
    showSelectAllCheckBox = true,
    loading = false,
    id = null,
    display = 'chip',
    style,
    disable = false,
}) => {
    return (
        <div className={`${className} d-flex flex-column gap-6px`}>
            <CommonLabel label={label} required={required} />
            <div className='w-100' style={{position: 'relative'}}>
                {/* <CustomLoader full visible={loading} /> */}
                <MultiSelect
                    id={id}
                    className={`text-md-regular ${textColorClassName} ${
                        errorMessage ? 'p-invalid' : ''
                    } w-100`}
                    filter
                    display={display}
                    value={selectedOptions}
                    style={{
                        fontFamily: fontsFamily.manrope,
                        ...style,
                    }}
                    options={options}
                    showSelectAll={showSelectAll}
                    optionLabel={valueKey}
                    onChange={onChange}
                    placeholder={
                        <div className='filter-placeholder'>
                            <i className={placeholderIcon} style={{marginRight: '5px'}}></i>
                            {placeholder}
                        </div>
                    }
                    name={name}
                    dropdownIcon={(opts) => {
                        return opts.iconProps['data-pr-overlay-visible'] ? (
                            <KTSVG
                                path='/media/icons/duotune/art/dropdownUpIcon.svg'
                                svgClassName='w-20px h-20px'
                            />
                        ) : (
                            <KTSVG
                                path='/media/icons/duotune/art/dropdownDownIcon.svg'
                                svgClassName='w-20px h-20px'
                            />
                        )
                    }}
                    loading={loading}
                    disabled={disable}
                />
            </div>
            {errorMessage ? (
                <div className='text-sm-regular text-sqError500'>{errorMessage}</div>
            ) : null}
        </div>
    )
}

export default CustomAutoCompleteDropdown
