import {useCallback, useEffect, useState} from 'react'
import CustomModal from '../../../../../../../customComponents/customModal/CustomModal'
import {Document, Page} from 'react-pdf'
import {getDownloadPathForUploadedDocumentService} from '../../../../../../../services/Services'
import CustomButton, {
    BUTTON_SIZE,
} from '../../../../../../../customComponents/customButtton/CustomButton'
import RejectAnimation from '../../../../../../../../src/assets/lottieAnimation/RejectAnimation.json'
import CustomLottie from '../../../../../../../customComponents/customLottie/CustomLottie'
import CustomSkeleton from '../../../../../../../customComponents/customSkeleton/CustomSkeleton'
import Show from '../../../../../../../customComponents/show/Show'
import CustomImage from '../../../../../../../customComponents/customImage/CustomImage'
import {IMAGE_TYPE} from '../../../../../../../helpers/CommonHelpers'
import axios from 'axios'

const DocumentPreviewModal = ({open, handleClose, docData, onDownload, directUrl = false}) => {
    const [docPages, setDocPages] = useState(null)
    const [docPath, setDocPath] = useState(null)
    const [loading, setloading] = useState(true)
    const [downloadLoading, setDownloadLoading] = useState(false)

    useEffect(() => {
        if (!directUrl) getDocumentData()
        else {
            setDocPath(docData?.url)
            setloading(false)
        }
    }, [])

    const getDocumentData = useCallback(() => {
        const body = {
            document_file_path: docData?.url,
        }
        getDownloadPathForUploadedDocumentService(body)
            .then((res) => {
                setDocPath(res?.data)
            })
            .catch(() => {})
            .finally(() => setloading(false))
    }, [docData])

    const onDocumentSucess = ({numPages}) => {
        setDocPages(numPages)
    }

    const downlaodFileMeathod = useCallback(
        (fileName, filePath) => {
            axios
                .get(filePath, {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/pdf',
                    },
                })
                .then((response) => {
                    let url = window.URL.createObjectURL(response?.data)
                    let a = document.createElement('a')
                    a.href = url
                    a.download = fileName
                    a.click()
                    a.remove()
                    downloadLoading(false)
                })
                .catch(() => {
                    setDownloadLoading(false)
                })
        },
        [downloadLoading]
    )
    const downloadPdfDocument = () => {
        setDownloadLoading(true)

        let file = docData?.url?.replace(/\/([^/]+)\.\w+$/, '$1')
        const fileName = `${file}.pdf`
        downlaodFileMeathod(fileName, docPath)
    }
    const downloadImageDocument = () => {
        setDownloadLoading(true)
        const body = {
            document_file_path: docData?.url,
        }
        getDownloadPathForUploadedDocumentService(body).then((res) => {
            let file = docData?.url?.replace(/\/([^/]+)\.\w+$/, '$1')
            const fileName = `${file}.${docData?.extension}`
            const filePath = res?.data

            downlaodFileMeathod(fileName, filePath)
        })
    }

    const footerComponent = (
        <div className=' d-flex flex-center mt-5'>
            <CustomButton
                buttonLabel={downloadLoading ? 'Downloading...' : 'Download'}
                buttonSize={BUTTON_SIZE.normal}
                onClick={docData?.extension == 'pdf' ? downloadPdfDocument : downloadImageDocument}
                loading={downloadLoading}
                disabled={loading}
            />
        </div>
    )

    return (
        <CustomModal
            show={open}
            onHide={handleClose}
            title={docData?.name}
            showline={false}
            maxWidth='800'
            footerComponent={footerComponent}
            headerBorderRadius='6px'
            borderRadius='0px'
        >
            <Show>
                <Show.When isVisible={loading}>
                    <CustomSkeleton className={'h-500px'} borderRadius={10} />
                </Show.When>
                <Show.Else>
                    <Show>
                        <Show.When isVisible={docData?.extension == 'pdf'}>
                            <div className='overflow-auto border border-2 border-cmGrey200'>
                                <div className='mx-auto mb-2  shadow-sm ' id='pdfDocument'>
                                    <Document
                                        file={docPath}
                                        onLoadSuccess={onDocumentSucess}
                                        error={failedPdfView}
                                        noData={failedPdfView}
                                        loading={
                                            <CustomSkeleton
                                                className={'h-500px'}
                                                borderRadius={10}
                                            />
                                        }
                                    >
                                        {Array.from(new Array(docPages), (no, index) => (
                                            <Page
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                            />
                                        ))}
                                    </Document>
                                </div>
                            </div>
                        </Show.When>
                        <Show.Else>
                            <ImageView
                                image={docPath}
                                downloadDocument={downloadImageDocument}
                                downloadLoading={downloadLoading}
                                loading={loading}
                            />
                        </Show.Else>
                    </Show>
                </Show.Else>
            </Show>
        </CustomModal>
    )
}

export default DocumentPreviewModal

const ImageView = ({image, downloadDocument, downloadLoading, loading}) => {
    const [loadingSrc, setLoadingSrc] = useState(false)
    const [loadError, setLoadError] = useState(null)

    const preloadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.src = src
            img.onload = () => resolve(src)
            img.onerror = reject
        })
    }

    useEffect(() => {
        setLoadingSrc(true)
        const loadImg = () => {
            preloadImage(image).catch(() => {
                setLoadingSrc(false)
                setLoadError(true)
            })
        }
        loadImg()
    }, [image])
    return (
        <>
            {' '}
            <div className='d-flex flex-center min-h-600px'>
                {loadError ? (
                    <failedPdfView />
                ) : (
                    <CustomImage
                        type={IMAGE_TYPE.companyLogo}
                        src={image}
                        style={{maxWidth: '750px', minWidth: '300px'}}
                    />
                )}
            </div>
            {/* <div className=' d-flex flex-center mt-5'>
                <CustomButton
                    buttonLabel={downloadLoading ? 'Downloading...' : 'Download'}
                    buttonSize={BUTTON_SIZE.normal}
                    onClick={downloadDocument}
                    loading={downloadLoading}
                    disabled={loading}
                />
            </div> */}
        </>
    )
}

const failedPdfView = () => (
    <div className='text-center  p-10' style={{borderRadius: 10}}>
        <div
            className='text-cmError mb-5'
            style={{fontSize: 19.42, lineHeight: '26.5px', fontWeight: 800}}
        >
            <div className='d-flex flex-center mb-5'>
                <CustomLottie lottieJson={RejectAnimation} height={50} width={50} />
            </div>
            Failed To Load Your Document
        </div>
    </div>
)
