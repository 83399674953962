import {useCallback, useMemo} from 'react'
import useBasedOnHost from './useBasedOnHost'
import useUnitTypes from './useUnitTypes'
import useBasedOnCompanyType, {COMPANY_TYPE} from './useBasedOnCompanyType'
import {getSortedObjectByKey} from '../helpers/CommonHelpers'
import {HOST_SERVERS} from '../constants/SERVERS'
import {AMAZON_S3_CONFIG} from '../constants/constants'
import {useSelector} from 'react-redux'
import {getEvereeConfigSelector} from '../redux/selectors/SettingsSelectors'

const useSequifiConfig = () => {
    const {basedOnCompanyType, companyType, isCompanyType} = useBasedOnCompanyType()
    const evereeConfig = useSelector(getEvereeConfigSelector)
    const {basedOnHost, moveCodeToServers, checkServers} = useBasedOnHost()
    const {unitTypes} = useUnitTypes()

    const REDLINE_UPFRONT_COMMISSION_CHANGES_TYPE = useMemo(() => {
        let type = [
            {name: 'All', value: ''},
            {name: 'Commission', value: 'Commission', unitTypeData: unitTypes.commissionAmountType},
            {name: 'Upfront', value: 'Upfront', unitTypeData: unitTypes.upfrontAmountType},
            {name: 'Withheld', value: 'Withheld', unitTypeData: unitTypes.withheldAmountType},
        ]
        if (!isCompanyType([COMPANY_TYPE.pest])) {
            type.push({
                name: 'Fixed Redline',
                value: 'Fixed Redline',
                unitTypeData: unitTypes.redlineAmountType,
            })
            type.push({
                name: 'Location Redline',
                value: 'Location Redline',
                unitTypeData: unitTypes.redlineAmountType,
            })
            type.push({
                name: 'Self Gen Commission',
                value: 'Self Gen Commission',
                unitTypeData: unitTypes.selfGenCommissionAmountType,
            })
        }
        return type
    }, [
        isCompanyType,
        unitTypes.commissionAmountType,
        unitTypes.redlineAmountType,
        unitTypes.selfGenCommissionAmountType,
        unitTypes.upfrontAmountType,
        unitTypes.withheldAmountType,
    ])

    const ORGANIZATION_CHANGES_TYPE = useMemo(() => {
        let type = [
            {name: 'All', value: ''},
            {name: 'Manager', value: 'Manager'},
            {name: 'Is manager', value: 'is manager'},
            {name: 'Position', value: 'Position'},
            {name: 'Team', value: 'Team'},
            {name: 'Additional Location', value: 'Additional Location'},
        ]
        if (!isCompanyType([COMPANY_TYPE.pest])) type.push({name: 'Self Gen', value: 'Self Gen'})
        return type
    }, [isCompanyType])

    const OVERRIDE_SETTELEMENT_TYPE = useMemo(() => {
        let type = [
            {
                name: 'Reconciliation',
                value: 'Reconciliation',
            },
        ]
        if (!isCompanyType([COMPANY_TYPE.pest])) {
            type.push({name: 'During M2', value: 'During M2'})
        } else if (isCompanyType([COMPANY_TYPE.pest])) {
            type.push({name: 'Initial Service', value: 'Initial Service'})
        }
        return type
    }, [isCompanyType])

    const TEMPLATE_DYNAMIC_FIELDS = useMemo(() => {
        let fieldData = {
            current_date: {key: 'Current_Date'},
            // Company Fieds
            company_logo: {key: 'Company_Logo'},
            company_name: {key: 'Company_Name'},
            company_address: {key: 'Company_Address'},
            company_phone: {key: 'Company_Phone'},
            company_email: {key: 'Company_Email'},
            company_website: {key: 'Company_Website'},

            // Employee Fields
            employee_id: {key: 'Employee_ID'},
            employee_name: {key: 'Employee_Name'},
            employee_position: {key: 'Employee_Position'},
            employee_office_location: {key: 'Office_Location'},
            employee_first_name: {key: 'Employee_first_name'},
            employee_address: {key: 'Employee_Address'},
            employee_ssn: {key: 'Employee_SSN'},
            wage_type: {key: 'Wage_Type'},
            pay_rate: {key: 'Pay_Rate'},
            pto_hours: {key: 'PTO_Hours'},
            unused_pto: {key: 'Unused_PTO'},
            overtime_rate: {key: 'Overtime_Rate'},
            employee_upfront_amount: {key: 'upfront_amount'},
            employee_agreement_start_date: {key: 'start_date'},
            employee_agreement_end_date: {key: 'end_date'},
            employee_comission: {key: 'commission'},
            employee_direct_override: {key: 'Direct_Override_Value'},
            employee_office_override: {key: 'Office_Override_Value'},
            employee_indirect_override: {key: 'InDirect_Override_Value'},
            employee_manager_full_name: {key: 'Employee_Manager_Name'},
            employee_manager_position: {key: 'Employee_Manager_Position'},
            employee_manager_department: {key: 'Employee_Manager_Department'},
            sender_name: {key: 'Sender_Name'},
            recruiter_name: {key: 'Recruiter_Name'},
        }
        if (!isCompanyType([COMPANY_TYPE.pest])) {
            fieldData.employee_redline_amount = {key: 'redline'}
        }
        return getSortedObjectByKey(fieldData)
    }, [isCompanyType])

    const getUnitTypeFromValue = useCallback((dataArry = [], value = '') => {
        return dataArry?.length > 0
            ? dataArry?.find((item) => item?.value == value)?.name ?? ''
            : ''
    }, [])

    const LOCATION_SHOW_LOCATION_FILTER = useMemo(() => {
        let type = [
            {
                name: `With ${basedOnCompanyType?.installationPartnerVerbiage}`,
                value: 'withInstallers',
            },
        ]
        if (basedOnCompanyType?.isActiveRedlineSystem) {
            type.push({
                name: 'With Work Site Id',
                value: 'withWorkSiteId',
            })
            type.push({
                name: 'With Only Redline',
                value: 'withOnlyStandard',
            })
        }
        return type
    }, [basedOnCompanyType?.installationPartnerVerbiage, basedOnCompanyType?.isActiveRedlineSystem])

    const DYNAMIC_ALERTS_CENTER_QUICK_FILTER = useMemo(() => {
        let type = [
            {name: 'Prospect ID', value: 'pid', type: 'sales'},
            {name: 'Customer Sign Off', value: 'customer_signoff', type: 'sales'},
            {name: 'Gross Amount Value', value: 'gross_account_value', type: 'sales'},
            {name: 'Customer Name', value: 'customer_name', type: 'sales'},
            {name: 'Customer State ', value: 'customer_state', type: 'sales'},
        ]
        if (basedOnCompanyType?.isCompanyTypePest) {
            type.push({name: 'Sales Rep', value: 'setter_id', type: 'missingRep'})
            type.push({
                name: 'Inital Service & Service Completion arrived',
                value: 'm1,m2',
                type: 'closedPayroll',
            })
            type.push({name: 'Inital Service arrived', value: 'm1', type: 'closedPayroll'})
            type.push({name: 'Service Completion arrived', value: 'm2', type: 'closedPayroll'})

            type.push({name: 'Service Partner', value: 'install_partner', type: 'sales'})
        } else {
            type.push({name: 'Setter', value: 'setter_id', type: 'missingRep'})
            type.push({name: 'Closer', value: 'sales_rep_email', type: 'missingRep'})
            type.push({name: 'M1 & M2 arrived', value: 'm1,m2', type: 'closedPayroll'})
            type.push({name: 'M1 arrived', value: 'm1', type: 'closedPayroll'})
            type.push({name: 'M2 arrived', value: 'm2', type: 'closedPayroll'})
            type.push({name: `${basedOnCompanyType?.epcText}`, value: 'epc', type: 'sales'})
            type.push({
                name: `Net ${basedOnCompanyType?.netEpcText}`,
                value: 'net_epc',
                type: 'sales',
            })
            type.push({name: 'Install Partner', value: 'install_partner', type: 'sales'})
        }
        return type
    }, [
        basedOnCompanyType?.epcText,
        basedOnCompanyType?.isCompanyTypePest,
        basedOnCompanyType?.netEpcText,
    ])

    const PAYROLL_DISPUTE_LIST = useMemo(() => {
        let data = [
            {name: 'Commission', value: 'Commission'},
            {name: 'Upfront', value: 'Upfront'},
            {name: 'Overrides', value: 'Overrides'},
            {name: 'Incentive/Bonus', value: 'Incentive/Bonus'},
            {name: 'Deductions', value: 'Deductions'},
            {name: 'Other', value: 'Other'},
        ]
        if (![COMPANY_TYPE.pest].includes(companyType)) {
            data.push({name: 'M1', value: 'M1'})
            data.push({name: 'M2', value: 'M2'})
        }
        return data
    }, [companyType])

    const excelImportSampleFile = useMemo(() => {
        let saleImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/import_format.xlsx`,
            jobImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/solar_sample_file.xlsx`,
            userImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/solar_sample_user_file.xlsx`

        // Sale / Job Import
        if (checkServers([HOST_SERVERS.flex])) {
            saleImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/flex_import_format.xlsx`
            jobImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/flex_sample_file.xlsx`
        } else if (checkServers([HOST_SERVERS.newera])) {
            saleImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/Newera_sample_file.xlsx`
            jobImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/newera_sample_file.xlsx`
        } else if (isCompanyType([COMPANY_TYPE.pest])) {
            saleImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/pest_import_format.xlsx`
            jobImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/pest_sample_file.xlsx`
        } else if (isCompanyType([COMPANY_TYPE.turf])) {
            saleImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/turf_import_format.xlsx`
            jobImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/turf_sample_file.xlsx`
        }

        // User Import
        if (isCompanyType([COMPANY_TYPE.pest])) {
            userImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/pest_sample_user_file.xlsx`
        } else if (isCompanyType([COMPANY_TYPE.turf])) {
            userImport = `${AMAZON_S3_CONFIG.s3_bucket_url}/excel_import/turf_sample_user_file.xlsx`
        }
        return {
            sale: saleImport,
            job: jobImport,
            user: userImport,
        }
    }, [checkServers, isCompanyType])

    const SALES_ACCOUNT_WITH = useMemo(() => {
        let data = [{name: 'Cancel Date', value: 'cancel_date'}]
        if ([COMPANY_TYPE.pest].includes(companyType)) {
            data.push({name: 'Initial Service Date', value: 'm1_date'})
            data.push({name: 'Service Complete Date', value: 'm2_date'})
            data.push({name: 'Initial Service & Service Complete Date', value: 'm1_date_m2_date'})
            data.push({name: 'Initial Service Paid', value: 'm1_paid'})
            data.push({name: 'Service Complete Paid', value: 'm2_paid'})
        } else {
            data.push({name: 'M1 Date', value: 'm1_date'})
            data.push({name: 'M2 Date', value: 'm2_date'})
            data.push({name: 'M1 & M2 Date', value: 'm1_date_m2_date'})
            data.push({name: 'M1 Paid', value: 'm1_paid'})
            data.push({name: 'M2 Paid', value: 'm2_paid'})
        }
        return data
    }, [companyType])

    const WORKER_TYPE = useMemo(() => {
        let types = [{name: 'Contractor', value: 'Contractor', typeValue: '1099'}]
        if (evereeConfig?.status) types.push({name: 'Employee', value: 'Employee', typeValue: 'w2'})
        return types
    }, [evereeConfig?.status])

    return {
        basedOnHost,
        moveCodeToServers,
        companyType,
        basedOnCompanyType,
        unitTypes,
        LOCATION_SHOW_LOCATION_FILTER,
        OVERRIDE_SETTELEMENT_TYPE,
        REDLINE_UPFRONT_COMMISSION_CHANGES_TYPE,
        ORGANIZATION_CHANGES_TYPE,
        TEMPLATE_DYNAMIC_FIELDS,
        getUnitTypeFromValue,
        DYNAMIC_ALERTS_CENTER_QUICK_FILTER,
        PAYROLL_DISPUTE_LIST,
        excelImportSampleFile,
        SALES_ACCOUNT_WITH,
        WORKER_TYPE,
    }
}

export default useSequifiConfig
