/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/admin/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes, SuspensedView} from './PrivateRoutes'
import {ErrorsPage} from '../app/modules/admin/errors/ErrorsPage'
import {AuthPage} from '../app/modules/admin/auth'
import {useSelector} from 'react-redux'
import {
    getTokenSelector,
    getUserIdSelector,
    isUserOnboardProcessDoneSelector,
} from '../redux/selectors/AuthSelectors'
import EmployeePage from '../app/modules/employee/EmployeePage'
import AddNewLeadPage from '../app/modules/common/newLead/AddNewLeadPage'
import Completion from '../integrations/stripe/Completion'
import StripeIntegration from '../integrations/stripe/StripeIntegration'
import SequidocDocumentRoutes from '../app/modules/sequidocDocumentSign/SequidocDocumentRoutes'
import {lazy, useEffect, useMemo, useRef, useState} from 'react'
import oneSignal from '../onesignal/oneSignal'
import App from '../app/App'
import MaintancePage from '../app/modules/admin/maintance/MaintancePage'
import {ref, onValue} from 'firebase/database'
import {firebaseDB} from '../firebse/firebase'
import {CURRENT_HOST} from '../constants/SERVERS'
import BackgroundVerificationRoutes from '../app/modules/common/backgroundVerification/BackgroundVerificationPage'
import SClearanceReportFullPage from '../app/modules/admin/s-Clearance/SClearanceReportFullPage'
import ForgotPassword from '../app/modules/admin/auth/components/ForgotPassword'
import ResetPassword from '../app/modules/admin/auth/components/resetPassword/ResetPassword'
import useSequifiConfig from '../sequiConfig/useSequifiConfig'
import {logoutAction} from '../redux/actions/AuthActions'
import {useDispatch} from 'react-redux'
import {lazyRetry} from '../helpers/CommonHelpers'
import CustomIframe from '../customComponents/customIframe/CustomIframe'
import {SEQUIFI_IMPORTANT_LINKS} from '../constants/constants'
import EvereeEmbededEmployeeOnboardingIframe from '../app/everee/evereeEmbededEmployeeOnboardingIframe/EvereeEmbededEmployeeOnboardingIframe'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes = () => {
    const isUserLoggedIn = useSelector(getTokenSelector)
    const userId = useSelector(getUserIdSelector)
    const isUserOnboardProcessDone = useSelector(isUserOnboardProcessDoneSelector)
    const lastWebsiteStatus = useRef(null)
    const [isWebsiteUnderMaintenance, setWebsiteUnderMaintenance] = useState('undefined')
    const {basedOnHost} = useSequifiConfig()
    const [directPath, setDirectPath] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        oneSignal.addNotificationSettingChangeListener((ifEnabled) => {
            if (ifEnabled && userId) oneSignal.login(userId)
        })
        return () => {
            oneSignal.removeNotificationSettingChangeListener()
        }
    }, [])

    useEffect(() => {
        if (userId) oneSignal.login(userId)
    }, [userId])

    useEffect(() => {
        lastWebsiteStatus.current = isWebsiteUnderMaintenance?.[CURRENT_HOST]
    }, [isWebsiteUnderMaintenance?.[CURRENT_HOST]])

    const ROUTE_WITHOUT_MAINTENANCE = useMemo(() => {
        return [
            'developer-config',
            'document-signing',
            'stripe',
            'completion',
            'referral',
            'background-verification',
            'reset-password',
        ]
    }, [])

    const ROUTE_WITHOUT_AUTH = useMemo(() => {
        return [
            'developer-config',
            'document-signing',
            'referral',
            'background-verification',
            'reset-password',
        ]
    }, [])

    useEffect(() => {
        const refData = ref(firebaseDB, 'maintanance')
        return onValue(refData, (snapshot) => {
            const data = snapshot?.val()
            const isRouteWithoutMaintenance = ROUTE_WITHOUT_MAINTENANCE.some((routeItem) =>
                window.location.pathname.includes(routeItem)
            )
            if (!data?.[CURRENT_HOST] && lastWebsiteStatus?.current && !isRouteWithoutMaintenance) {
                window.location.reload(true)
                dispatch(logoutAction(false, true))
            } else {
                setWebsiteUnderMaintenance(data)
            }
        })
    }, [])

    useEffect(() => {
        const canRouteRunWithoutAuthentication = ROUTE_WITHOUT_AUTH.some((routeItem) =>
            window.location.pathname.includes(routeItem)
        )
        if (!isUserLoggedIn && !canRouteRunWithoutAuthentication) {
            let finalHref = window.location.href?.replace(window.location.origin, '')
            setDirectPath(finalHref)
        }
    }, [])

    const DeveloperConfigRoute = lazy(() =>
        lazyRetry(() => import('../test/developerConfig/DeveloperConfigPage'), 'DeveloperConfig')
    )

    return (
        <BrowserRouter basename='/'>
            <Routes>
                {isWebsiteUnderMaintenance != 'undefined' ? (
                    <>
                        <Route element={<App />}>
                            {basedOnHost.developerConfig ? (
                                <Route
                                    path='developer-config/*'
                                    element={<DeveloperConfigRoute />}
                                />
                            ) : null}
                            <Route
                                path='background-verification/:userId'
                                element={<BackgroundVerificationRoutes />}
                            />
                            <Route path='document-signing/*' element={<SequidocDocumentRoutes />} />
                            <Route path='stripe' element={<StripeIntegration />} />
                            <Route path='completion' element={<Completion />} />
                            <Route path={'referral'} element={<AddNewLeadPage />} />
                            <Route path='reset-password/:passToken' element={<ResetPassword />} />
                            <Route
                                path='privacy-policy'
                                element={
                                    <CustomIframe src={SEQUIFI_IMPORTANT_LINKS.privacy_policy} />
                                }
                            />
                            <Route
                                path='terms-of-use'
                                element={
                                    <CustomIframe src={SEQUIFI_IMPORTANT_LINKS.terms_of_use} />
                                }
                            />
                        </Route>
                        {isWebsiteUnderMaintenance?.[CURRENT_HOST] ? (
                            <Route element={<App />}>
                                <Route path='/*' element={<MaintancePage />} />
                            </Route>
                        ) : (
                            <Route element={<App />}>
                                <Route path='error/*' element={<ErrorsPage />} />
                                <Route
                                    path='everee'
                                    element={<EvereeEmbededEmployeeOnboardingIframe />}
                                />
                                {isUserLoggedIn ? (
                                    <>
                                        {isUserOnboardProcessDone ? (
                                            <>
                                                <Route
                                                    path='/*'
                                                    element={
                                                        <PrivateRoutes
                                                            directPath={directPath}
                                                            setDirectPath={setDirectPath}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path='sclearancereport'
                                                    element={<SClearanceReportFullPage />}
                                                />
                                                <Route
                                                    index
                                                    element={<Navigate to={'/dashboard'} />}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Route
                                                    path='/*'
                                                    element={
                                                        <SuspensedView>
                                                            <EmployeePage />
                                                        </SuspensedView>
                                                    }
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Route path='auth/*' element={<AuthPage />} />
                                        <Route path='*' element={<Navigate to='/auth' />} />
                                    </>
                                )}
                            </Route>
                        )}
                    </>
                ) : null}
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}
