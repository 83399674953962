import CustomNoData from '../../../../../customComponents/customNoData/CustomNoData'
import Show from '../../../../../customComponents/show/Show'
import EvereeEmbededEmployeeOnboardingIframe from '../../../../everee/evereeEmbededEmployeeOnboardingIframe/EvereeEmbededEmployeeOnboardingIframe'

const EmployeOnboardingTaxBankingInfoIframeStep = ({
    globalCompanyProfile,
    evereeOnboardingIframeURL = null,
    isEvereeOnboardingCompleted,
    onEvereeStatusChange = () => {},
}) => {
    return (
        <div className='w-100 mx-auto' style={{fontSize: '14px', fontFamily: 'Manrope'}}>
            <div className='text-cmGrey500 text-center mb-10 '>
                Welcome to <span className='text-cmGrey800'> {globalCompanyProfile?.name}</span>,
                Please Complete The Following Fields. Please ensure the following information is
                correct before proceeding to the next section.
            </div>
            <div
                className='bg-cmwhite shadow-sm mb-10 w-sm-75 w-100 mx-auto py-5 '
                style={{borderRadius: '10px'}}
            >
                <div
                    className='text-cmGrey600 text-center mb-10 required'
                    style={{fontWeight: '700', fontFamily: 'Manrope', fontSize: '16px'}}
                >
                    Tax + Banking Information
                </div>
                <div>
                    <Show>
                        <Show.When isVisible={isEvereeOnboardingCompleted}>
                            <div
                                className='d-flex align-items-center justify-content-center align-items-center'
                                style={{height: 300}}
                            >
                                <CustomNoData
                                    label={'You have completed your everee onboarding process'}
                                />
                            </div>
                        </Show.When>
                        <Show.When isVisible={evereeOnboardingIframeURL ? true : false}>
                            <div style={{height: 400}}>
                                <EvereeEmbededEmployeeOnboardingIframe
                                    src={evereeOnboardingIframeURL}
                                    onStatusChange={onEvereeStatusChange}
                                />
                            </div>
                        </Show.When>
                        <Show.Else>
                            <CustomNoData label='No Url Found for onboarding' />
                        </Show.Else>
                    </Show>
                </div>
            </div>
        </div>
    )
}

export default EmployeOnboardingTaxBankingInfoIframeStep
