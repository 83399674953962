import {setGlobalAccessRights} from '../../accessRights/AccessRights'
import {ROLES} from '../../accessRights/AccessRightsConstants'
import CustomToast from '../../customComponents/customToast/CustomToast'
import {getErrorMessageFromResponse, sendDataToReducer} from '../../helpers/CommonHelpers'
import {getUserProfileService, loginService, logoutService} from '../../services/Services'
import {
    SET_ACCESS_RIGHTS,
    SET_ACTIVE_ROLE,
    SET_TOKEN,
    SET_USER_DATA,
    USER_IS_LOGGED_IN,
} from '../reducers/AuthReducer'
import {getUserDataSelector} from '../selectors/AuthSelectors'
import {RESET_STORE, RESET_STORE_TO_DEFAULT} from '../types/ReduxTypes'
import {getPositionSettingAction} from './SettingActions'
import oneSignal from '../../onesignal/oneSignal'

// Set Active Role
export const setActiveRoleAction = (role) => (dispatch) =>
    new Promise((resolve, reject) => {
        sendDataToReducer(dispatch, SET_ACTIVE_ROLE, role)
    })

// Login
export const loginAction = (res, isClockIn) => (dispatch) =>
    new Promise((resolve, reject) => {
        if (res?.data?.access_rights?.length > 0) {
            sendDataToReducer(dispatch, SET_USER_DATA, {
                ...res?.data,
                token: res?.token,
                lastLogin: res?.last_login,
                isClockIn,
            })
            oneSignal.promptForNotificationPermissionAndGetPlayerId()
            sendDataToReducer(dispatch, USER_IS_LOGGED_IN, true)
            dispatch(setAccessRightsAction(res?.data?.access_rights))
            const isStandardAvailable = res?.data?.access_rights?.some(
                (item) => item?.name == ROLES.standard.roleValue
            )
            const isAdminAvailable = res?.data?.access_rights?.some(
                (item) => item?.name == ROLES.administrator.roleValue
            )
            dispatch(
                setActiveRoleAction(
                    res?.data?.is_super_admin
                        ? ROLES.administrator.roleValue
                        : isStandardAvailable
                        ? ROLES.standard.roleValue
                        : isAdminAvailable
                        ? ROLES.administrator.roleValue
                        : null
                )
            )
            dispatch(getPositionSettingAction())
            resolve(res?.data)
            sendDataToReducer(dispatch, SET_TOKEN, res.token)
        } else {
            CustomToast.error('This user have not any assigned role')
            reject('This user have not any assigned role')
        }
    })

// Logout
export const logoutAction =
    (forceLogout = false, resetToDefaultReduxStore = false) =>
    (dispatch) =>
        new Promise((resolve, reject) => {
            oneSignal.logout()
            if (!forceLogout) {
                logoutService()
                    .then(() => {
                        localStorage.removeItem('token')
                        if (resetToDefaultReduxStore) dispatch({type: RESET_STORE_TO_DEFAULT})
                        else dispatch({type: RESET_STORE})
                        setTimeout(() => {
                            window.location.reload(true)
                        }, 500)
                        resolve(true)
                    })
                    .catch((e) => {
                        CustomToast.error(getErrorMessageFromResponse(e))
                    })
            } else {
                localStorage.removeItem('token')
                if (resetToDefaultReduxStore) dispatch({type: RESET_STORE_TO_DEFAULT})
                else dispatch({type: RESET_STORE})
                setTimeout(() => {
                    window.location.reload(true)
                }, 500)
                resolve(true)
            }
        })

export const setAccessRightsAction = (accessRights) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        // const state = getState()
        // const rightsData = setGlobalAccessRights(state?.auth?.userData?.access_rights)
        const rightsData = setGlobalAccessRights(accessRights)
        sendDataToReducer(dispatch, SET_ACCESS_RIGHTS, rightsData)
        resolve(true)
    })

export const getUserProfileAction = () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const state = getState()
        const userData = getUserDataSelector(state)
        getUserProfileService()
            .then((res) => {
                const data = {
                    ...userData,
                    ...res?.data,
                    name: `${res?.data?.first_name} ${res?.data?.last_name}`,
                }
                sendDataToReducer(dispatch, SET_USER_DATA, data)
                dispatch(setAccessRightsAction(res?.data?.access_rights))
                resolve(res?.data)
            })
            .catch(reject)
    })
